<template>
  <!--此部分根据设计自动生成，手动修改节点标记daltag="manual"属性，则该节点及子节点不会被自动生成代码覆盖，如发现其他需要调整框架情形请联系:alex.c@daline.com.cn-->
  <div id="Digitalcapitalization" style="width:100vw">
    <!-- <div v-for="imginfo in imgList" :key="imginfo.imgID" style="height:50vh">
        <img :src="imginfo.imgURL"  >
    </div> -->
    <div id="ac88fdc4">数字资产化</div>
    <div class="grid_layout__wrap">
      <div id="bfdc84af"
           v-for="article of articleList"
           :key="article.articleID"
           @click="clk_bfdc84af(article)">
        <img :src="article.articleImg"
             id="a347d451" />
        <div id="a9c797e4">
          {{ article.articleTitle | contentFilter }}
        </div>
        <div id="a962bc4d"
             class="ellipsis-3">
          {{ article.articleSummary }}
        </div>
      </div>
    </div>

    <div id="f617e258">
      <div id="a1b536d9">解决方案</div>
      <div id="solution__wrap">
        <div class="solution_item left solution_info"
             @click="clk_bfdc84a(digitallist1)">
          <p>{{ digitallist1.articleTitle }}</p>
          <p class="ellipsis-3">{{digitallist1.articleSummary}}</p>
          <div class="btn-wrapper">
            <button class="btn dal-btn text-lg">了解详情</button>
          </div>
        </div>
        <div class="solution_item right">
          <img :src="digitallist1.articleImg"
               @click="clk_bfdc84af(digitallist1)" />
        </div>
        <!-- <div
          class="solution_item left solution_info"
          @click="clk_bfdc84a(digitallist2)"
        >
          <p>{{ digitallist2.articleTitle }}</p>
          <p class="ellipsis-6">{{ digitallist2.articleSummary }}</p>
        </div>
        <div class="solution_item left">
          <img :src="digitallist2.articleImg" />
        </div> -->
      </div>
      <!-- <div class="more">
        <button class="btn dal-btn">了解更多</button>
      </div> -->
    </div>
    <div id="a10578fe">客户案例</div>
    <CardStackCarousel height="500px">
      <CardStackCarouselItem v-for="article in caseList"
                             :key="article.articleID">
        <div class="example_wrap"
             @click="clk_bfdc84af(article)">
          <img id="a5db1b86"
               :src="article.articleImg" />
          <p>{{ article.articleTitle }}</p>
        </div>
      </CardStackCarouselItem>
    </CardStackCarousel>
    <div id="ac82d91f">
      <dal-compfloat id="cmpac82d91f"> </dal-compfloat>
    </div>
  </div>
</template>
<script>
//此文件根据设计自动生成编写框架，请在指定位置编写逻辑代码，如发现框架需要调整的情形请联系:alex.c@daline.com.cn
//在此注释下方添加自定义import
import {
  DALINEWEB_ARTICLECENTER_V0X0X1_getArticlewithToped,
  // DALINEWEB_IMGCENTER_V0X0X1_getImgList
} from '../api/dalapi.js'
//在此注释下方添加自定义函数
//此注释下方为自动引用部分，请不要修改
import dal_compfloat from '../components/dal-compfloat'
import CardStackCarousel from '@/components/card_swiper/card-stack-carousel.vue'
import CardStackCarouselItem from '@/components/card_swiper/card-stack-carousel-item.vue'
export default {
  components: {
    'dal-compfloat': dal_compfloat,
    CardStackCarousel,
    CardStackCarouselItem
  },
  filters: {//限制文本显示字数,超出部分用...代替
    contentFilter (value) {
      if (!value) return ''
      // console.log('@@@@@@@@@@@@@', value)
      if (value.length > 25) {
        return value.slice(0, 25)
      }
      return value
    },
  },
  data () {
    return {
      //在此注释下方添加自定义变量
      articleList: [],
      digitallist2: {},
      digitallist1: {},
      caseList: [],
      articleContent1: '',
      articleContent2: '',
      imgList: ''
    }
  },
  mounted () {
    this.refreshPage()
    this.freshdigital()
    this.getCasesdata()
    this.freshdigital2()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    async clk_bfdc84a (article) {
      this.$router.push({
        path: 'article',
        query: {
          articleID: article.articleID,
          articleType: article.articleType
        }
      })
    },
    async clicka9dafc00 () {
      this.$router.push('article')
    },
    //在此注释下方添加自定义函数或事件
    clk_bfdc84af (article) {
      this.$router.push({
        path: 'article',
        query: {
          articleID: article.articleID,
          articleType: article.articleType
        }
      })
    },
    async refreshPage () {
      let dicInput = {
        records: 6,
        sort_rules: "orderID",
        ArticleType: 169,
      };
      dicInput.status = "0";
      const res = await DALINEWEB_ARTICLECENTER_V0X0X1_getArticlewithToped(
        dicInput
      );
      const articleList = res.content.resContent
      this.articleList = articleList
    },

    async freshdigital () {
      let dicInput = {
        records: 1,
        ArticleType: 170,
        sort_rules: "orderID"
      };
      dicInput.status = "0";

      const res = await DALINEWEB_ARTICLECENTER_V0X0X1_getArticlewithToped(
        dicInput
      );
      const digitallist = res.content.resContent
      if (digitallist.length > 0) {
        this.digitallist1 = digitallist[0]
      }
    },
    async freshdigital2 () {
      let dicInput = {
        records: 1,
        ArticleType: 168,
        sort_rules: "orderID"
      };
      dicInput.status = "0";

      const res = await DALINEWEB_ARTICLECENTER_V0X0X1_getArticlewithToped(
        dicInput
      );
      const digitallist = res.content.resContent
      if (digitallist.length > 0) {
        this.digitallist2 = digitallist[0]
      }
    },
    contentFilter (value) {
      if (!value) return ''
      if (value.length > 25) {
        return value.slice(0, 25) + "..."
      }
      return value
    },
    async getCasesdata () {
      let dicInput = {
        ArticleType: 171,
        records: 6,
        ofield: "insertTime",
        sort: "",
        sort_rules: "orderID"
      };
      dicInput.status = "0";
      const res = await DALINEWEB_ARTICLECENTER_V0X0X1_getArticlewithToped(
        dicInput
      );
      const articleList = res.content.resContent
      this.caseList = articleList
    },
  }
}
</script>
<style lang="scss" scoped>
#Digitalcapitalization {
  font-size: calc(0.5vw + 0.8em);
  background: #fbfbfe;
}
/*此部分根据设计自动生成，自定义样式追加到该部分结尾处*/
.ellipsis-3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
.ellipsis-6 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  overflow: hidden;
}
.title {
  position: relative;
  padding-bottom: 0.5em;
  margin-bottom: 1em;
  text-align: center;
  font: 500 2em/1.5 "PingFang SC";
  font-size: 1.5em;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 2em;
    height: 0.15em;
    background: #e74c3c;
  }
}
.grid_layout__wrap {
  padding: 0 15vw;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2em;
  img {
    border-radius: 1.5em;
  }
}
#a5db1b86{
  height: 200px;
      object-fit: contain;
}
#Digitalcapitalization {
  padding-bottom: 50px;
}
#a2802625 {
  width: 100vw;
  height: 50vh;
  position: relative;
}
#a2802625 {
  background-image: url("../assets/img-a1455d8c.png");
  background-size: cover;
}
#ac88fdc4 {
  padding-top: 100px;
  @extend .title;
}

#add98deb {
  padding: 0 15vw;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  position: relative;
  margin-top: 30px;
}
#add98deb::after {
  content: "";
  width: 30%;
}
#bfdc84af {
  cursor: pointer;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
#a347d451 {
  width: 100%;
  /* width: 385px; */
  // height: 300px;
  object-fit: cover;
}
#a347d451 {
  vertical-align: middle;
}
#a9c797e4 {
  margin-top: 1em;
  text-align: center;
  color: #000;
  font: bold 1em/1.2 "PingFang SC";
}
#a962bc4d {
  margin-top: 1em;
  font: normal 0.75em/1.5 "PingFang SC";
  color: #727272;
  @extend .ellipsis-3;
}

#af99a463 {
  text-align: left;
  color: #727272;
  font-size: 25px;
  font-family: PingFang SC;
}
#a77cde55 {
  width: 385px;
  position: relative;
  margin-left: 32px;
  display: inline-block;
  vertical-align: middle;
}
#ad0ca4dc {
  width: 385px;
  height: 273px;
}
#ad0ca4dc {
  vertical-align: middle;
}
#a8b409f6 {
  margin-top: 30px;
}
#a8b409f6 {
  text-align: left;
  color: #000;
  font-size: 30px;
  font-family: PingFang SC;
}
#af33675c {
  width: 302px;
  position: relative;
  margin-top: 10px;
  padding-left: 42px;
}
#a64bf308 {
  width: 302px;
}
#a64bf308 {
  text-align: left;
  color: #727272;
  font-size: 25px;
  font-family: PingFang SC;
}
#a29e819e {
  width: 385px;
  position: relative;
  margin-left: 31px;
  display: inline-block;
  vertical-align: middle;
}
#b235a520 {
  width: 385px;
  height: 273px;
}
#b235a520 {
  vertical-align: middle;
}
#a55111fc {
  margin-top: 30px;
}
#a55111fc {
  text-align: left;
  color: #000;
  font-size: 30px;
  font-family: PingFang SC;
}
#ae51f30d {
  width: 302px;
  position: relative;
  margin-top: 10px;
  padding-left: 42px;
}
#a3c97075 {
  width: 302px;
}
#a3c97075 {
  text-align: left;
  color: #727272;
  font-size: 25px;
  font-family: PingFang SC;
}
#aa82133d {
  width: 1218px;
  position: relative;
  margin-top: 30px;
  padding-left: 351px;
}
#cb341d96 {
  width: 385px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
#a5a6d904 {
  width: 385px;
  height: 273px;
}
#a5a6d904 {
  vertical-align: middle;
}
#a9a8c5d0 {
  margin-top: 30px;
}
#a9a8c5d0 {
  text-align: left;
  color: #000;
  font-size: 30px;
  font-family: PingFang SC;
}
#a1fd3d1f {
  width: 302px;
  position: relative;
  margin-top: 10px;
  padding-left: 42px;
}
#a6188e55 {
  width: 302px;
}
#a6188e55 {
  text-align: left;
  color: #727272;
  font-size: 25px;
  font-family: PingFang SC;
}
#bc5bb457 {
  width: 385px;
  position: relative;
  margin-left: 32px;
  display: inline-block;
  vertical-align: middle;
}
#a36f6e88 {
  width: 385px;
  height: 273px;
}
#a36f6e88 {
  vertical-align: middle;
}
#a96410de {
  margin-top: 30px;
}
#a96410de {
  text-align: left;
  color: #000;
  font-size: 30px;
  font-family: PingFang SC;
}
#a53160db {
  width: 302px;
  position: relative;
  margin-top: 10px;
  padding-left: 42px;
}
#a2cefeee {
  width: 302px;
}
#a2cefeee {
  text-align: left;
  color: #727272;
  font-size: 25px;
  font-family: PingFang SC;
}
#ac4218c6 {
  width: 385px;
  position: relative;
  margin-left: 31px;
  display: inline-block;
  vertical-align: middle;
}
#a729cf22 {
  width: 385px;
  height: 273px;
}
#a729cf22 {
  vertical-align: middle;
}
#a9090348 {
  margin-top: 30px;
}
#a9090348 {
  text-align: left;
  color: #000;
  font-size: 30px;
  font-family: PingFang SC;
}
#a5d3c480 {
  width: 302px;
  position: relative;
  margin-top: 10px;
  padding-left: 42px;
}
#ae2be5d3 {
  width: 302px;
}
#ae2be5d3 {
  text-align: left;
  color: #727272;
  font-size: 25px;
  font-family: PingFang SC;
}
#f617e258 {
  display: flex;
  flex-direction: column;
  width: 100vw;
  // height: 100vh;
  position: relative;
  margin-top: 50px;
}
#f617e258 {
  // background-image: url("../assets/img-ad347181.jpg");
  // background-size: cover;
  // background-position: center;
  color: #000;
  background: #fff;
}
#a1b536d9 {
  padding-top: 1em;
  color: inherit;
  @extend .title;
  &::after {
    // background: #fff;
  }
}
#solution__wrap {
  flex: 1;
  padding: 1em 15vw 4em;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 4em;
  column-gap: 0em;
  cursor: pointer;
  // &:extend(.ellipsis-3);
  .solution_info {
    box-sizing: border-box;
    padding: 0 10%;
    width: 100%;
  }
  .solution_info_right {
    box-sizing: border-box;
    padding: 0 10%;
    // margin-left: 20%;
    width: 100%;
  }
  .solution_item {
    font-size: 1.5em;
    color: inherit;
    p:nth-child(1) {
      font-size: 1.2em;
      // text-align: center;
    }
    p:nth-child(2) {
      margin-top: 1em;
      font-size: 0.75em;
      // text-align: center;
    }
  }
  .left {
    text-align: left;
  }
  .right {
    text-align: right;
  }
  img {
    // width: 60%;
    // width: auto;
    width: 100%;
    border-radius: 0.2em;
    // height: 30vh;
    // object-fit: cover;
  }
}
.btn-wrapper {
  margin-bottom: 3em;
  margin-top: 1.5em;
  button {
    font-size: 0.6em;
    padding: 0.75em 3em;
  }
}
.more {
  padding: 2em 0;
  button {
    padding: 0.5em 2em;
    font-size: 1.5em;
    color: #000;
    background: #fff;
  }
}
#a1b536d9 {
  text-align: center;
  // color: #fff;
  font-size: 1.5em;
  font-family: PingFang SC;
}
#a3c59294 {
  padding: 0 15vw;
  display: flex;
  justify-content: space-around;
  position: relative;
  margin-top: 60px;
}
#f8a0f4bc {
  width: 609px;
  height: 214px;
  display: inline-block;
  vertical-align: middle;
  object-fit: cover;
}
#f8a0f4bc {
  vertical-align: middle;
}

#a0513f71 {
  color: #fff;
  font-size: 36px;
  font-family: PingFang SC;
}
#a3d277be {
  width: 468px;
  position: relative;
  margin-top: 30px;
}
#a6dcccbb {
  width: 468px;
}
#a6dcccbb {
  text-align: left;
  color: #fff;
  font-size: 24px;
  font-family: PingFang SC;
}
#ae155e4f {
  padding: 0 15vw;
  display: flex;
  justify-content: space-around;
  position: relative;
  margin-top: 60px;
}

#e3c96ee9 {
}
#e3c96ee9 {
  text-align: right;
  color: #fff;
  font-size: 36px;
  font-family: PingFang SC;
}
#ee26c2e8 {
  width: 478px;
  position: relative;
  margin-top: 30px;
}
#acc7fcb2 {
  width: 478px;
}
#acc7fcb2 {
  text-align: left;
  color: #fff;
  font-size: 24px;
  font-family: PingFang SC;
}
#d928a51a {
  width: 609px;
  height: 214px;
  display: inline-block;
  vertical-align: middle;
  object-fit: cover;
}
#d928a51a {
  vertical-align: middle;
}
#a9dafc00 {
  margin: auto;
  width: 299px;
  margin-top: 155px;
}
#a9dafc00 {
  background-color: rgb(255, 255, 255);
  border-radius: 10px 10px 10px 10px;
}
#a9dafc00 {
  color: #141620;
  font-size: 40px;
  font-family: PingFang SC;
  border: transparent;
}
#a10578fe {
  margin-top: 2em;
  @extend .title;
}
#d3eb3415 {
  width: 1218px;
  position: relative;
  margin-top: 60px;
  padding-left: 351px;
  padding-bottom: 365px;
}
#fe1e72b2 {
  width: 68px;
  height: 68px;
  padding-top: 110px;
  padding-bottom: 133px;
  display: inline-block;
  vertical-align: middle;
}
#fe1e72b2 {
  vertical-align: middle;
}
#a13f6a6f {
  width: 765px;
  height: 311px;
  display: inline-block;
  vertical-align: middle;
  object-fit: cover;
}
#a13f6a6f {
  vertical-align: middle;
}
#a555dd9e {
  width: 68px;
  height: 68px;
  margin-left: 159px;
  padding-top: 110px;
  padding-bottom: 133px;
  display: inline-block;
  vertical-align: middle;
}
#a555dd9e {
  vertical-align: middle;
}
#ac82d91f {
  width: 150px;
  position: fixed;
  z-index: 50;
  top: 2908px;
  left: 1740px;
}
#ac82d91f {
  background-color: rgb(20, 22, 32);
  border-radius: 5px 5px 5px 5px;
}
.more {
  display: flex;
  justify-content: center;
}
/*在此注释下方添加自定义样式*/

.example_wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  align-items: center;
  img {
    width: 25%;
    border-radius: 10px;
  }
  p {
    padding: 1em;
    font-size: 1.2em;
    font-weight: 700;
  }
}
</style>
